<template>
  <v-card>
    <div v-if="isTokenValid">
      <v-card-title>{{ $t("page.claimAccount.title") }}</v-card-title>
      <v-card-text>
        <v-form v-model="form">
          <v-text-field
            v-model="credentials.email"
            :label="$t('page.resetPassword.fields.email.title')"
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            v-model="credentials.password"
            :label="$t('page.claimAccount.fields.password.title')"
            :rules="passwordRules"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            :label="$t('page.resetPassword.fields.passwordConfirm.title')"
            :rules="confirmPasswordRules"
          ></v-text-field>
          <!-- <v-text-field
            v-model="credentials.username"
            :label="$t('page.claimAccount.fields.username.title')"
            @change="verifyUsername()"
            :rules="[
              (v) =>
                !!!v ||
                isUsernameTaken ||
                $t('page.claimAccount.fields.username.errors.taken'),
            ]"
          ></v-text-field> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          class="primary px-12"
          :disabled="!form"
          @click="claimAccount()"
          >{{ $t("page.claimAccount.actions.submit") }}</v-btn
        >
      </v-card-actions>
    </div>
    <div v-else>
      <v-card-text>
        <h2>{{ tokenStatusText }}</h2>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
import lengthRules from "/rules/length-rule";
export default {
  props: ["token"],
  components: {},
  data() {
    return {
      form: null,
      confirmPassword: null,
      usernameChangeTimeout: null,
      isUsernameTaken: false,
      credentials: {},
      tokenStatus: null,
      tokenStatusLoading: true,
      emailRules: [
        (v) => {
          var re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
          return re.test(v) || this.$t("page.resetPassword.fields.email.valid");
        },
      ],
      passwordRules: [
        lengthRules.notEmpty(
          this.$t("page.resetPassword.fields.passwordConfirm.cannotBeEmpty")
        ),
        lengthRules.min(
          8,
          this.$t("page.resetPassword.fields.password.minLength", {
            minLength: 8,
          })
        ),
        (v) => {
          var re =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
          return (
            re.test(v) ||
            this.$t("page.resetPassword.fields.password.specialCharacters")
          );
        },
      ],
      confirmPasswordRules: [
        (v) =>
          !!v ||
          this.$t("page.resetPassword.fields.passwordConfirm.cannotBeEmpty"),
        (v) =>
          v == this.credentials.password ||
          this.$t("page.resetPassword.fields.passwordConfirm.doesNotMatch"),
      ],
    };
  },
  computed: {
    isTokenValid() {
      if (this.tokenStatusLoading || this.tokenStatus == null) return false;
      else return this.tokenStatus.isValid;
    },
    tokenStatusText() {
      if (!this.tokenStatusLoading) {
        if (this.tokenStatus == null) {
          return this.$t("page.claimAccount.token.dontExists");
        } else if (this.tokenStatus.status == "Claimed")
          return this.$t("page.claimAccount.token.claimed");
        else if (this.tokenStatus.status == "Expired")
          return this.$t("page.claimAccount.token.expired");
        else return this.$t("page.claimAccount.token.cancelled");
      }
      return "Loading";
    },
  },
  watch: {},
  methods: {
    claimAccount() {
      var reqObj = {
        ...this.credentials,
        token: this.token,
      };
      //console.log(reqObj);
      this.$store.dispatch("auth/claimAccount", reqObj);
    },
    verifyUsername() {
      if (this.usernameChangeTimeout != null)
        clearTimeout(this.usernameChangeTimeout);
      if (!this.credentials.username || this.credentials.username.length == 0) {
        this.isUsernameTaken = false;
      } else {
        this.isUsernameTaken = false;
        this.usernameChangeTimeout = setTimeout(() => {
          this.$store
            .dispatch("user/isUserNameTaken", this.credentials.username)
            .then((res) => {
              this.isUsernameTaken = res;
            });
        }, 500);
      }
    },
  },
  async created() {
    await this.$store
      .dispatch("user/fetchInvitationStatus", { token: this.token })
      .then((x) => {
        this.tokenStatus = x;
      })
      .finally(() => {
        this.tokenStatusLoading = false;
      });
  },
};
</script>